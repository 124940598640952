<template>
    <div class="px-2 pb-6">
        <!-- Displaying the row of images -->
        <v-row>
            <!-- The first image is always displayed separately in a column -->
            <v-col class="pa-1" :cols="12">
                <v-img @click="openDialog(0)" max-height="150" :src="images[0]" height="100%" cover></v-img>
            </v-col>

            <!-- Loop through the remaining images and display them in columns -->
            <v-col v-for="(image, imgIdx) in displayedImages" :key="imgIdx" :cols="computedColSize"
                class="d-flex flex-column pa-1">
                <v-img @click="openDialog(imgIdx + 1)" max-height="150" :src="image" height="100%" cover>
                    <!-- Conditional overlay for additional images when maxImages limit is reached -->
                    <template v-if="showOverlay && imgIdx === maxImages - 2">
                        <div class="overlay">
                            +{{ additionalImages }}
                        </div>
                    </template>
                </v-img>
            </v-col>
        </v-row>

        <!-- Dialog to show a full-screen carousel for viewing images -->
        <v-dialog v-model="dialogFullImage" max-width="80%">
            <v-card>
                <v-carousel v-model="activeImageIndex" hide-delimiters>
                    <!-- Loop through all images and display them as carousel items -->
                    <v-carousel-item v-for="(image, index) in images" :key="index">
                        <v-img :src="image" height="500px" cover></v-img>
                    </v-carousel-item>
                </v-carousel>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import store, { EventBus } from "../../../store"; // Importing Vuex store and EventBus for state management
import { isMobile } from "../../../lib/mobileUtil"; // Utility function to check if the user is on a mobile device

export default {
    // Props passed to the component
    props: ['images'],

    data() {
        return {
            state: store.state, // Vuex store state
            ownUUID: store.state.ownUUID, // Unique identifier for the user
            maxImages: 4, // Maximum number of images to display in the grid (excluding the main image)
            dialogFullImage: false, // Boolean to toggle the visibility of the image dialog
            activeImageIndex: 0, // Index of the currently active image in the carousel
        };
    },

    methods: {
        // Method to open the dialog and set the active image index
        openDialog(index) {
            this.activeImageIndex = index;
            this.dialogFullImage = true;
        },
    },

    computed: {
        // Compute the column size dynamically based on the number of remaining images
        computedColSize() {
            const remainingImages = this.images.length - 1;
            if (remainingImages === 1) return 12; // Single image takes full width
            if (remainingImages === 2) return 6; // Two images take half width each
            return 4; // Default size for more than two images
        },

        // Get the list of images to display, excluding the first one
        displayedImages() {
            return this.images.slice(1, this.maxImages);
        },

        // Check if the overlay should be shown for additional images
        showOverlay() {
            return this.images.length > this.maxImages;
        },

        // Calculate the number of additional images not displayed in the grid
        additionalImages() {
            return this.images.length - this.maxImages;
        },
    },
};
</script>

<style scoped lang="scss">
/* Styling for the overlay to indicate additional images */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent dark background */
    color: white;
    /* Text color for the overlay */
    font-size: 24px;
    /* Font size of the overlay text */
    font-weight: bold;
    /* Bold text */
    z-index: 1;
    /* Ensure the overlay appears above other elements */
}
</style>
