<template>
  <div :class="[
      isDarkMode ? 'bg-dark' : 'bg-light',
    ]"
    class="d-flex flex-column pa-3 height-custom overflow-auto pt-6">
    <SocialMediaProfileContent />
  </div>
</template>

<script>
  import store from "../../../store";
  import SocialMediaProfileContent from "./socialMediaProfileContent.vue";
  // const hasOwn = Object.prototype.hasOwnProperty;
  export default {
    components: { SocialMediaProfileContent },
    data() {
      return {
        state: store.state,
      };
    },
    methods: {},
    computed: {
      isDarkMode() {
      return this.state.persisted.isDark;
    },
    },
    mounted() {
    },
    unmounted() {
    },
  };
</script>

<style scoped lang="scss">
.bg-light {
  background-color: var(--v-primary-100);
}
.bg-dark {
  background-color: var(--v-primary-900);
}
.height-custom {
  height: calc(100% + 60px);
}
</style>

