<template>
  <v-card flat class="notification-box rounded-sm my-6">
    <v-card-title class="text-h6 mt-2">
      Kurzbeschreibung Tätigkeit:
    </v-card-title>
    <v-card-text class="font-weight-light">
      Ich bin seit August 2019 Leiterin der RA-MICRO Landesrepräsentanz Sachsen und widme mich hier
      insbesondere mit dem Organisieren der Präsenzveranstaltungen. Außerdem bin ich Teil des Teams
      Vertrieb Ost und berate Kanzleien zu unseren Produkten.
    </v-card-text>
  </v-card>
</template>

<script>
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";

export default {
  props: ["sidebar", "mini"],
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  watch: {},
  mounted() { },
  unmounted() { },
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss"></style>