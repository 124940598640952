<template>
    <div>
        <div>
            <v-btn icon variant="text" @click="goBack" class="mb-4">
                <font-awesome-icon :icon="['fal', 'arrow-left']" :style="{ fontSize: '20px' }" />
            </v-btn>
        </div>
        <v-row class="d-flex" no-gutters>
            <v-col cols="12" md="6" class="px-4 pb-4">
                <Profile />
                <UserOverview />
                <!--Other 2 useroverview box title + text just for demo-->
                <v-card flat class="notification-box pa-3 rounded-sm my-6">
                    <div class="text-subtitle-1 pb-2 font-weight-medium">
                        Kenntnisse/Fähigkeiten:
                    </div>
                    <div class="text-body">
                        Beratung · Kommunikationsfähigkeit · Kundenbetreuung · Mediation · Networking ·
                        Organisationstalent
                        ·
                        Personalmanagement · Projektmanagement · Social Media · Vertrieb
                    </div>
                </v-card>
                <v-card flat class="notification-box pa-3 rounded-sm my-6">
                    <div class="text-subtitle-1 pb-2 font-weight-medium">
                        Hobbies/Freizeit:
                    </div>
                    <div class="text-body">
                        Fotografie, Spazieren gehen, kleine Kinder erschrecken
                    </div>
                </v-card>

                <!--Finish 2 boxes useroverview-->

            </v-col>
            <v-col cols="12" md="6" class="px-4 pb-4">
                <h3 class="font-weight-regular">Vita</h3>
                <v-divider class="mb-6"></v-divider>
                <Vita />

                <!--Other vita box title + list just for demo-->

                <v-card flat class="notification-box rounded-sm my-6">
                    <v-card-title class="text-h6 mt-2 pb-0">

                        Berufserfahrung:
                    </v-card-title>
                    <v-card-text>
                        <v-list class="bg-transparent">
                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium">
                                        Leiterin Vertrieb Deutschland
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-grey-600 font-weight-light">
                                        07/2019 - 09/2020
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-grey-600 font-weight-light">
                                        Amazon EU S.à r.l., München
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium">
                                        Teamleitung Kundenbetreuung
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-grey-600 font-weight-light">
                                        01/2017 - 06/2019
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-grey-600 font-weight-light">
                                        1&1 Telecommunication SE, Montabaur
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium">
                                        Kundenberaterin Online
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-grey-600 font-weight-light">
                                        04/2015 - 12/2016
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-grey-600 font-weight-light">
                                        Mobilcom-Debitel GmbH, Stuttgart
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium">
                                        Praktikantin Kundenbetreuung
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-grey-600 font-weight-light">
                                        01/2015 - 03/2015
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-grey-600 font-weight-light">
                                        Mobilcom-Debitel GmbH, Stuttgart
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                    </v-card-text>
                </v-card>

                <!--finish box vita -->

            </v-col>
        </v-row>

        <v-row class="d-flex pt-6" no-gutters>
            <v-col cols="12" class="px-4">
                <h3>Beiträge</h3>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="6" class="pa-4">
                <Post />
            </v-col>
            <v-col cols="12" md="6" class="pa-4">
                <Post />
            </v-col>
        </v-row>

    </div>
</template>

<script>
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import moment from "../../../../sharedsrc/moment";
import CreatePost from "./createPost.vue";
import Post from "./post.vue";
import Notification from "./notifications.vue";
import Profile from "./profile.vue";
import Vita from "./vita.vue";
import UserOverview from "./userOverview.vue";

export default {
    props: [],
    components: { CreatePost, Post, Notification, Profile, Vita, UserOverview },
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
        };
    },
    watch: {},
    mounted() { },
    unmounted() { },
    methods: {
        goBack() {
            this.$router.back();
        },
    },
    computed: {},
};
</script>

<style scoped lang="scss">
.adobe-link {
    position: absolute;
    top: 0;
    right: 15px;
}
</style>