<template>
    <div>
        <v-card flat class="px-4 rounded-sm" style="height: 160px;">
            <div class="d-flex align-end">
                <router-link to="/socialmedia/profile" class="d-flex align-center text-decoration-none">
    <v-avatar size="80" class="ml-3 mr-6">
        <v-img src="/img/sm-layout/user1.png" cover />
    </v-avatar>
</router-link>


                <div class="flex-grow-1">
                    <v-tabs v-model="selectedTab" class="text-sm mb-2" align-tabs="start"
                        background-color="transparent" dense>
                        <v-tab :min-width="50" class="font-weight-light text-capitalize px-3">Beitrag</v-tab>
                        <v-tab :min-width="50" class="font-weight-light text-capitalize px-3">Erledigt</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="selectedTab">
                        <v-tab-item v-if="selectedTab === 0">
                            <div class="mt-4 position-relative">
                                <v-textarea variant="outlined" label="Was möchten Sie Ihren Kolleginnen und Kollegen mitteilen?"
                                    rows="2" hide-details />
                                    <v-btn v-bind="props" icon variant="text" size="small" class="me-auto position-absolute bottom-0 ml-1">
                                        <font-awesome-icon :icon="['fal', 'image']" :style="{ fontSize: '20px' }" />
                                    </v-btn>
                                    <!--<div class="mt-4 d-flex">
                                    <v-btn v-bind="props" icon variant="text" size="small" class="me-auto">
                                        <font-awesome-icon :icon="['fal', 'camera']" :style="{ fontSize: '20px' }" />
                                    </v-btn>
                                    <v-btn v-bind="props" flat color="primary">Posten</v-btn>
                                </div>-->
                            </div>
                        </v-tab-item>

                        <v-tab-item v-if="selectedTab === 1">
                            <div class="text-center mt-4">
                                <v-textarea variant="outlined" label="Was haben Sie erledigt?" rows="2" hide-details>
                                </v-textarea>
                                <!--<div class="mt-4 d-flex flex-row-reverse">
                                    <v-btn v-bind="props" flat color="primary">Posten</v-btn>
                                </div>-->
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </v-card>
    </div>
</template>


<script>
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";


export default {
    props: ["sidebar", "mini"],
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            selectedTab: 0,
        };
    },
    watch: {},
    mounted() { },
    unmounted() { },
    methods: {
        gotoPage(route) {
            return this.setCurrentContentVisile(route, true, this.$router);
        },
        setCurrentContentVisile(...args) {
            return store.setCurrentContentVisile(...args);
        },
    },
    computed: {},
};
</script>

<style scoped lang="scss">
.v-tab.v-tab--selected .v-tab__slider {
    height: 1px !important;
}

</style>
