<template>
  <v-card flat class="notification-box pa-2 rounded-sm bg-primary" style="height: 160px;">
    <v-card-title class="pb-2 font-weight-regular">
      Benachrichtigungen
    </v-card-title>
    <v-card-text>
      <p>
        - Coder Christian gefällt Ihr Beitrag <span class="text-caption text-white opacity-50">01.07.2022, 8:25
          Uhr</span>
      </p>
      <p>
        - Kontrolle Karen hat auf Ihren <a href="#" class="text-white">Kommentar</a> geantwortet <span
          class="text-caption text-white opacity-50">30.06.2022, 15:53 Uhr</span>
      </p>
      <p>
        - Techniker Tim hat auf Ihren Beitrag geantwortet <span class="text-caption text-white opacity-50">27.06.2022,
          11:34 Uhr</span>
      </p>
      <p>
        - Netzwerk Nico gefällt Ihr Kommentar <span class="text-caption text-white opacity-50">25.06.2022, 13:23
          Uhr</span>
      </p>
      <!-- <v-list class="bg-transparent">
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              - Coder Christian gefällt Ihr Beitrag <span class="text-caption text-white opacity-50">01.07.2022, 8:25
                Uhr</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              - Kontrolle Karen hat auf Ihren <a href="#" class="text-white">Kommentar</a> geantwortet <span
                class="text-caption text-white opacity-50">30.06.2022, 15:53 Uhr</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              - Techniker Tim hat auf Ihren Beitrag geantwortet <span
                class="text-caption text-white opacity-50">27.06.2022, 11:34 Uhr</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              - Netzwerk Nico gefällt Ihr Kommentar <span class="text-caption text-white opacity-50">25.06.2022, 13:23
                Uhr</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </v-card-text>
  </v-card>
</template>

<script>
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";

export default {
  props: ["sidebar", "mini"],
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  watch: {},
  mounted() { },
  unmounted() { },
  methods: {},
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
  },
};
</script>

<style scoped lang="scss"></style>