// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styling for the overlay to indicate additional images */
.overlay[data-v-eb38029e] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent dark background */
  color: white;
  /* Text color for the overlay */
  font-size: 24px;
  /* Font size of the overlay text */
  font-weight: bold;
  /* Bold text */
  z-index: 1;
  /* Ensure the overlay appears above other elements */
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/socialMedia/socialMediaGallery.vue","webpack://./socialMediaGallery.vue"],"names":[],"mappings":"AACA,0DAAA;AACA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oCAAA;EACA,qCAAA;EACA,YAAA;EACA,+BAAA;EACA,eAAA;EACA,kCAAA;EACA,iBAAA;EACA,cAAA;EACA,UAAA;EACA,oDAAA;ACAJ","sourcesContent":["\n/* Styling for the overlay to indicate additional images */\n.overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(0, 0, 0, 0.5);\n    /* Semi-transparent dark background */\n    color: white;\n    /* Text color for the overlay */\n    font-size: 24px;\n    /* Font size of the overlay text */\n    font-weight: bold;\n    /* Bold text */\n    z-index: 1;\n    /* Ensure the overlay appears above other elements */\n}\n","/* Styling for the overlay to indicate additional images */\n.overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.5);\n  /* Semi-transparent dark background */\n  color: white;\n  /* Text color for the overlay */\n  font-size: 24px;\n  /* Font size of the overlay text */\n  font-weight: bold;\n  /* Bold text */\n  z-index: 1;\n  /* Ensure the overlay appears above other elements */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
