<template>
    <div>
        <v-row class="d-flex" no-gutters>
            <v-col cols="12" md="6" class="px-4 pb-4">
                <CreatePost />
            </v-col>
            <v-col cols="12" md="6" class="px-4 pb-4">
                <Notification />
            </v-col>
        </v-row>
        <!-- <img class="socialMediaImg" src="/img/vOfficeSocialMediaPic.png" /> -->

        <v-row class="d-flex" no-gutters>
            <v-col cols="12" md="6" class="pa-4">
                <h3 class="font-weight-regular">Unternehmen</h3>
                <v-divider class="mb-6"></v-divider>
                <!--post templates only for layout-->
                <div>
                    <v-card flat class="pa-3 my-6 rounded-sm">
                        <v-row class="d-flex align-start">
                            <v-col cols="auto">
                                <v-avatar size="60">
                                    <v-img src="/img/sm-layout/user.png" cover />
                                </v-avatar>
                            </v-col>
                            <v-col class="pt-4 pl-0">
                                <div class="font-weight-medium">Leiterin Leonie</div>
                                <div class="text-medium-emphasis text-caption">01.Jul.2025 16:45 Uhr</div>
                            </v-col>
                        </v-row>

                        <v-row class="mt-2 mb-2">
                            <v-col>
                                <div>
                                    <p class="mb-2">Zu unserer heutigen Veranstaltung zum Thema vOffice sind 20 Personen
                                        gekommen. Die Landes-
                                        repräsentanz konnten das gerade noch so aufnehmen, für die Zukunft sollten wir
                                        wohl über eine
                                        maximale Zahl an Anmeldungen nachdenken. Wir haben viele interessante Gespräche
                                        geführt und viel
                                        darüber gelernt, was der Anwaltschaft beim ortsunabhängigen Arbeiten wirklich
                                        wichtig ist. Das
                                        erfreu-
                                        liche Ergebnis des Abends: Wir konnten mit jeder der anwesenden Kanzleien einen
                                        Vertrag
                                        abschlie-
                                        ßen!
                                    </p>
                                    <p class="mb-2">
                                        Ich bin gespannt, ob unsere After-Work-Veranstaltung nächsten Mittwoch auch so
                                        ein großer Erfolg
                                        wird.
                                    </p>
                                </div>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row justify="end" class="mt-2 pb-2">
                            <v-btn variant="text" class="me-4">
                                <v-icon size="20">mdi-thumb-up-outline</v-icon>
                                <span class="ms-2 text-capitalize font-weight-regular">Gefällt mir</span>
                            </v-btn>
                            <v-btn @click="toggleCommentSection" variant="text">
                                <v-icon size="20">mdi-chat-outline</v-icon>
                                <span class="ms-2 text-capitalize font-weight-regular">Kommentieren</span>
                            </v-btn>
                        </v-row>
                    </v-card>
                </div>
                <div>
                    <v-card flat class="pa-3 my-6 rounded-sm">
                        <v-row class="d-flex align-start">
                            <v-col cols="auto">
                                <v-avatar size="60">
                                    <v-img src="https://randomuser.me/api/portraits/men/4.jpg" cover />
                                </v-avatar>
                            </v-col>
                            <v-col class="pt-4 pl-0">
                                <div class="font-weight-medium">Verkaufstalent Valentin</div>
                                <div class="text-medium-emphasis text-caption">01.Jul.2025 16:45 Uhr</div>
                            </v-col>
                        </v-row>

                        <v-row class="mt-2 mb-2">
                            <v-col>
                                <div>
                                    <p class="mb-2">Heute war ich bei einem Kunden in Hamburg und nach einem sehr
                                        erfolgreichen Gespräch will dieser
                                        jetzt 1.000 Plätze RA-MICRO 365 bestellen! Ich bin ja gespannt, ob ihr die noch
                                        dieses Jahr fertig
                                        bekommt <a href="">@Zimmermann</a> Mirco 😂
                                    </p>
                                </div>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row justify="end" class="mt-2 pb-2">
                            <v-btn variant="text" class="me-4">
                                <v-icon size="20">mdi-thumb-up-outline</v-icon>
                                <span class="ms-2 text-capitalize font-weight-regular">Gefällt mir</span>
                            </v-btn>
                            <v-btn @click="toggleCommentSection" variant="text">
                                <v-icon size="20">mdi-chat-outline</v-icon>
                                <span class="ms-2 text-capitalize font-weight-regular">Kommentieren</span>
                            </v-btn>
                        </v-row>
                    </v-card>
                </div>
                <div>
                    <v-card flat :class="[
                        isDarkMode ? 'bg-grey-darken-3' : 'bg-grey-lighten-2',
                    ]" class="pa-3 my-6 rounded-sm">
                        <v-row>
                            <v-col>
                                <div>
                                    <div class="font-weight-medium pb3">Assistentin Anna <span
                                            class="text-medium-emphasis text-caption">hat heute für die Firma
                                            erledigt:</span>
                                    </div>
                                    <p class="mb-2">
                                        Flugtickets für Frau Vorstand gebucht, Pakete angenommen und die Inhalte in die
                                        entsprechenden
                                        Abteilungen verteilt, Termin mit Fensterreiniger gemacht, Auswertungen
                                        Arbeitszeit Juli.
                                    </p>

                                </div>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row justify="end" class="mt-2 pb-2">
                            <v-btn variant="text" class="me-4">
                                <v-icon size="20">mdi-thumb-up-outline</v-icon>
                                <span class="ms-2 text-capitalize font-weight-regular">Gefällt mir</span>
                            </v-btn>
                            <v-btn @click="toggleCommentSection" variant="text">
                                <v-icon size="20">mdi-chat-outline</v-icon>
                                <span class="ms-2 text-capitalize font-weight-regular">Kommentieren</span>
                            </v-btn>
                        </v-row>
                    </v-card>
                </div>
                <!--finish post templates only for layout-->

                <Post />
            </v-col>

            <v-col cols="12" md="6" class="pa-4">
                <h3 class="font-weight-regular">Favoriten</h3>
                <v-divider class="mb-6"></v-divider>
                <Post />

            </v-col>
        </v-row>

        <!-- <a href="https://xd.adobe.com/view/3997d75f-b00a-47ce-b0ea-8a2535b34856-6cc4/" target="_blank"
            class="adobe-link text-primary">Demo</a>-->
    </div>
</template>



<script>
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import moment from "../../../../sharedsrc/moment";
import CreatePost from "./createPost.vue";
import Post from "./post.vue";
import Notification from "./notifications.vue";

export default {
    props: [],
    components: { CreatePost, Post, Notification },
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
        };
    },
    watch: {},
    mounted() { },
    unmounted() { },
    methods: {},
    computed: {
        isDarkMode() {
      return this.state.persisted.isDark;
    },
},
};
</script>

<style scoped lang="scss">
.adobe-link {
    position: absolute;
    top: 0;
    right: 15px;
}
</style>