<template>
    <v-card flat class="pa-3 my-6 rounded-sm">
        <v-row class="d-flex align-start">
            <v-col cols="auto">
                <v-avatar size="60">
                    <v-img src="/img/sm-layout/user.png" cover />
                </v-avatar>
            </v-col>
            <v-col class="pt-4 pl-0">
                <div class="font-weight-medium">Messerschmidt Miriam</div>
                <div class="text-medium-emphasis text-caption">01.Jul.2025 16:45 Uhr</div>
            </v-col>
        </v-row>

        <v-row class="mt-2 mb-2">
            <v-col>
                <div>
                    <p class="mb-2">Hier ein paar Eindrücke von unserem Messestand auf der Virtueller Anwalt Messe 2022. Wir hatten
                        heute über 200 Kontakte und sind daher mit dem Ergebnis bisher sehr zufrieden.
                    </p>
                </div>
            </v-col>
        </v-row>

        <SocialMediaGallery v-if="imagesTest.length" :images="imagesTest" />

        <v-divider></v-divider>

        <v-row justify="end" class="mt-2 pb-2">
            <v-btn variant="text" class="me-4">
                <v-icon size="20">mdi-thumb-up-outline</v-icon>
                <span class="ms-2 text-capitalize font-weight-regular">Gefällt mir</span>
            </v-btn>
            <v-btn @click="toggleCommentSection" variant="text">
                <v-icon size="20">mdi-chat-outline</v-icon>
                <span class="ms-2 text-capitalize font-weight-regular">Kommentieren</span>
            </v-btn>
        </v-row>

        <!-- Comment Section Toggle -->
        <v-row v-if="showComments">
            <v-col cols="auto" class="d-flex justify-center">
                <v-avatar size="48">
                    <v-img src="https://randomuser.me/api/portraits/men/5.jpg" cover />
                </v-avatar>
            </v-col>
            <v-col class="pb-0">
                <v-textarea v-model="newComment" variant="outlined" label="Schreiben Sie einen Kommentar" rows="2" />
            </v-col>
        </v-row>

        <!-- Existing Comments -->
        <v-row v-if="showComments">
            <v-col>
                <v-divider></v-divider>
                <div v-for="(comment, index) in comments" :key="index" class="mt-4">
                    <v-row class="d-flex align-start">
                        <v-col class="d-flex justify-center" cols="auto">
                            <v-avatar size="36">
                                <v-img :src="comment.avatar" cover />
                            </v-avatar>
                        </v-col>
                        <v-col>
                            <div class="border-sm rounded-sm pa-4">
                                <div class="font-weight-bold">{{ comment.name }}</div>
                                <div>{{ comment.text }}</div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row justify="space-between" class="d-flex align-center mt-0">
                        <div class="text-muted text-caption pl-12 ml-6">{{ comment.date }}</div>
                        <div>
                            <v-btn v-bind="props" icon variant="text" size="small" class="me-2">
                                <v-icon size="16" class="me-1">mdi-thumb-up-outline</v-icon>
                                <span class="me-2">{{ comment.likes }}</span>
                            </v-btn>
                            <v-btn v-bind="props" variant="text" size="small">
                                Antworten
                            </v-btn>
                        </div>
                    </v-row>

                </div>
            </v-col>
        </v-row>

    </v-card>
</template>

<script>
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import SocialMediaGallery from "./socialMediaGallery.vue";

export default {
    props: [],
    components: { SocialMediaGallery },
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            imagesTest: [
                "https://fastly.picsum.photos/id/22/4434/3729.jpg?hmac=fjZdkSMZJNFgsoDh8Qo5zdA_nSGUAWvKLyyqmEt2xs0",
                "https://fastly.picsum.photos/id/0/5000/3333.jpg?hmac=_j6ghY5fCfSD6tvtcV74zXivkJSPIfR9B8w34XeQmvU",
                "https://fastly.picsum.photos/id/13/2500/1667.jpg?hmac=SoX9UoHhN8HyklRA4A3vcCWJMVtiBXUg0W4ljWTor7s",
                "https://fastly.picsum.photos/id/17/2500/1667.jpg?hmac=HD-JrnNUZjFiP2UZQvWcKrgLoC_pc_ouUSWv8kHsJJY",
                "https://fastly.picsum.photos/id/20/3670/2462.jpg?hmac=CmQ0ln-k5ZqkdtLvVO23LjVAEabZQx2wOaT4pyeG10I",
                "https://fastly.picsum.photos/id/28/4928/3264.jpg?hmac=GnYF-RnBUg44PFfU5pcw_Qs0ReOyStdnZ8MtQWJqTfA"
            ],
            showComments: false,
            newComment: '',
            comments: [
                {
                    name: 'Max Mustermann',
                    date: '01.01.2025 9:00 Uhr',
                    text: 'Toller Beitrag, danke für die Eindrücke!',
                    avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
                    likes: 2
                },
                {
                    name: 'Julia Müller',
                    date: '01.01.2025 9:30 Uhr',
                    text: 'Sehr informativ, gerne mehr davon!',
                    avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
                    likes: 5
                }
            ]
        };
    },
    methods: {
        toggleCommentSection() {
            this.showComments = !this.showComments;
        },
        addComment() {
            if (this.newComment.trim()) {
                this.comments.push({
                    name: 'You',
                    date: new Date().toLocaleString(),
                    text: this.newComment,
                    avatar: 'https://randomuser.me/api/portraits/men/4.jpg', // You can change this to a dynamic avatar
                    likes: 0
                });
                this.newComment = '';
            }
        }
    }
};
</script>


<style scoped lang="scss"></style>