<template>
  <v-card flat class="notification-box rounded-sm my-6">
    <v-card-title class="text-h6 mt-2 pb-0">
      Ausbildung:
    </v-card-title>
    <v-card-text>
      <v-list class="bg-transparent">
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              Betriebswirtschaftslehre
            </v-list-item-title>
            <v-list-item-subtitle class="text-grey-600 font-weight-light">
              02/2010 - 01/2015
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-grey-600 font-weight-light">
              Chambers of Commerce and Industry, Bonn
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              Ausbildung zur Industriekauffrau
            </v-list-item-title>
            <v-list-item-subtitle class="text-grey-600 font-weight-light">
              09/2006 - 08/2009
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-grey-600 font-weight-light">
              Deutsche Telekom AG, Bonn
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";

export default {
  props: ["sidebar", "mini"],
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  watch: {},
  mounted() { },
  unmounted() { },
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss"></style>