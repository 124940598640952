<template>
  <div :class="[
    isDarkMode ? 'bg-dark' : 'bg-light',
  ]" class="d-flex flex-column pa-3 height-custom overflow-auto pt-6">

    <SocialMediaContent />
    <!-- <div class="mb-2">
      <v-tabs v-model="selectedTab" class="text-sm mb-2 px-4" align-tabs="start" background-color="transparent" dense>
        <v-tab class="font-weight-regular text-capitalize">Posts & Aktivitäten</v-tab>
        <v-tab class="font-weight-regular text-capitalize">Profile</v-tab>
      </v-tabs>
    </div>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-if="selectedTab === 0">
          <SocialMediaContent />
        </v-tab-item>

        <v-tab-item v-if="selectedTab === 1">
          <SocialMediaProfileContent />
        </v-tab-item>
      </v-tabs-items> -->
  </div>
</template>


<script>
import store from "../../../store";
import SocialMediaContent from "./socialMediaContent.vue";
import SocialMediaProfileContent from "./socialMediaProfileContent.vue";
// const hasOwn = Object.prototype.hasOwnProperty;
export default {
  components: { SocialMediaContent, SocialMediaProfileContent },
  data() {
    return {
      state: store.state,
      selectedTab: 0,
    };
  },
  methods: {},
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
  },
  mounted() {
  },
  unmounted() {
  },
};
</script>

<style scoped lang="scss">
.bg-light {
  background-color: var(--v-primary-100);
}

.bg-dark {
  background-color: var(--v-primary-900);
}

.height-custom {
  height: calc(100% + 60px);
}
</style>
