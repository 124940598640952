<template>
    <!--border: status color-->
    <v-card flat class="border-primary rounded-sm" style="border: 2px solid #2a3133">
        <div class="position-relative">
            <v-img src="/img/sm-layout/profil.png" height="110px" cover />
            <v-avatar size="115" class="me-4 border-lg border-opacity-100 border-background position-absolute" style="top:30px; left:20px">
                <v-img src="/img/sm-layout/user.png" cover />
            </v-avatar>
        </div>

        <div class="px-6 pt-10">
             <v-row class="mb-4">
                <v-col cols="12" class="pb-0">
                    <div class="d-flex flex-column justify-end mb-3">
                        <div class="font-weight-medium text-h6">Lena Leiterin</div>
                        <div class="text-subtitle-2 text-medium-emphasis">Projektmanagerin</div>
                    </div>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <div>
                        <span class="font-weight-medium">Abteilung:</span> LRPR Sachsen, Vertrieb
                    </div>
                    <div>
                        <span class="font-weight-medium">Teams:</span> LRPR Sachsen, Vertrieb Ost
                    </div>
                </v-col>

                <v-col cols="12" md="6" class="py-0 text-right">
                    <div>
                        <span class="font-weight-bold">Telefon:</span> +49 30 43598 1234
                    </div>
                    <div>
                        <span class="font-weight-bold">Email:</span> l.leiterin@mail.com
                    </div>
                </v-col>
            </v-row>
        </div>

        <div class="d-flex justify-end px-4 bg-primary">
            <v-btn v-bind="props" icon variant="text" color="white">
                <font-awesome-icon :icon="['fal', 'star']" :style="{ fontSize: '20px' }" />
            </v-btn>
            <v-btn v-bind="props" icon variant="text" color="white">
                <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' }" />
            </v-btn>
            <v-btn v-bind="props" icon variant="text" color="white">
                <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
            </v-btn>
        </div>
    </v-card>
</template>



<script>
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";


export default {
    props: ["sidebar", "mini"],
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            selectedTab: 0,
        };
    },
    watch: {},
    mounted() { },
    unmounted() { },
    methods: {},
    computed: {},
};
</script>

<style scoped lang="scss"></style>