// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-tab.v-tab--selected .v-tab__slider[data-v-4e61ad12] {
  height: 1px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/socialMedia/createPost.vue","webpack://./createPost.vue"],"names":[],"mappings":"AACA;EACI,sBAAA;ACAJ","sourcesContent":["\n.v-tab.v-tab--selected .v-tab__slider {\n    height: 1px !important;\n}\n\n",".v-tab.v-tab--selected .v-tab__slider {\n  height: 1px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
